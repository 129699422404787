<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-toilet</v-icon>
               <h2>¿Usted baja la palanca de su inodoro tras haber ido al baño?</h2>
           </v-col>
           <v-col cols="12" sm="12" md="4" class="pb-0" justify="center">
               <v-chip-group
                    v-model="inodoro"
                    column
                    :error-messages="inodoroErrors"
                    class="text-center"
                    @change="setInodoro($event)"
                >
                    <v-chip
                    filter
                    outlined
                    value="si"
                    >
                        Si
                    </v-chip>

                    <v-chip
                    filter
                    outlined
                    value="no"
                    >
                        No
                    </v-chip>

                    <v-chip
                    filter
                    outlined
                    value="aveces"
                    >
                        A Veces
                    </v-chip>
               </v-chip-group>
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q13"
                >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q15"
                    :disabled="!inodoro"
                    >
                    Continuar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
           <v-col cols="12" sm="8" class="text-justify">
                <v-alert
                    outlined
                    color="info"
                    >
                    <div class="title">
                        Atento!!
                    </div>
                    <div>
                        El inodoro puede ser el consumidor de agua más grande en su casa. Así que recuerde, "Si es amarillo, déjelo. Si es de un color más oscuro, baje la palanca. Cada vez que usted evita bajar la palanca del inodoro, está ahorrando 30 litros.
                    </div>
                </v-alert>
           </v-col>
       </v-row>
   </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, numeric } from 'vuelidate/lib/validators'

export default {
    
    mixins: [validationMixin],

    validations: {
      inodoro: { required, numeric },
    },

    data() {
        return {
            inodoro: ''
        }
    },

    mounted() {
        this.inodoro = this.$store.state.inodoro
        this.$store.state.progress = 80
        this.goBackIfEmpty()
    },

    computed: {
        inodoroErrors () {
            const errors = []
            if (!this.$v.inodoro.$dirty) return errors
            !this.$v.inodoro.required && errors.push('Preferencias de inodoro es requerido.')
            !this.$v.inodoro.numeric && errors.push('Debe ser un número.')
            return errors
        },
    },

    methods: {
        setInodoro(value) {
            this.$store.state.inodoro = value
            this.$v.inodoro.$touch()
        },
       goBackIfEmpty(){
           if(!this.$store.state.municipio) {
                this.$router.push('/municipio')
           }else if(!this.$store.state.personas) {
                this.$router.push('/personas')
           }else if(!this.$store.state.cereales) {
                this.$router.push('/q1')
           }else if(!this.$store.state.carnes) {
               this.$router.push('/q2')
           }else if(!this.$store.state.lacteos) {
               this.$router.push('/q3')
           }else if(!this.$store.state.huevos) {
               this.$router.push('/q4')
           }else if(!this.$store.state.comida) {
               this.$router.push('/q5')
           }else if(!this.$store.state.dulces) {
               this.$router.push('/q6')
           }else if(!this.$store.state.verduras) {
               this.$router.push('/q7')
           }else if(!this.$store.state.frutas) {
               this.$router.push('/q8')
           }else if(!this.$store.state.tuberculos) {
               this.$router.push('/q9')
           }else if(!this.$store.state.cafe) {
               this.$router.push('/q10')
           }else if(!this.$store.state.tee) {
               this.$router.push('/q11')
           }else if(!this.$store.state.ducha) {
               this.$router.push('/q12')
           }else if(!this.$store.state.manos) {
               this.$router.push('/q13')
           }
       }
    },
}
</script>

<style>
    .v-slide-group__content{
        justify-content: center;
    }
</style>